<template>
  <div class="flex flex-col h-screen">
    <Header />
    <div class="z-10 flex flex-1 overflow-hidden">
      <Sidebar v-if="renderDesktopSidebar" />
      <main scroll-region class="flex-1 w-full overflow-y-scroll">
        <DefaultPageWrapper v-if="renderDefaultPageWrapper">
          <slot />
        </DefaultPageWrapper>
        <slot v-else />
      </main>
    </div>

    <FlashMessage class="mt-20" />
    <CommandPalette />
  </div>
</template>

<script setup>
import { ref, provide, computed } from 'vue'
import { usePage } from '@inertiajs/vue3'

import FlashMessage from '@/Components/Shared/FlashMessage'
import Header from '@/Components/Company/Header/Header'
import Sidebar from '@/Components/Company/Sidebar/Sidebar'
import DefaultPageWrapper from '@/Components/Company/PageWrapper/DefaultPageWrapper'
import CommandPalette from '@/Components/Shared/CommandPalette/CommandPalette'

const page = usePage()

const renderDesktopSidebar = computed(() => page.props.meta?.renderDesktopSidebar ?? true)

const renderDefaultPageWrapper = computed(() => page.props.meta?.renderDefaultPageWrapper ?? true)

const sidebarOpen = ref(false)
provide('sidebarOpen', sidebarOpen)

const toggleSidebarOpen = () => (sidebarOpen.value ^= true)
provide('toggleSidebarOpen', toggleSidebarOpen)
</script>
