import {
  CashIcon,
  ChartPieIcon,
  CollectionIcon,
  CreditCardIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SparklesIcon,
  TemplateIcon,
} from '@heroicons/vue/outline'

export default function useHoustonNavigation() {
  return {
    overviewTitle: 'Aryeo Mission Control',
    overviewRouteName: 'admin.web.legacy.houston-overview.show',
    sections: [
      {
        show: true,
        name: 'Quick Actions',
        description: null,
        children: [
          {
            show: true,
            name: 'Company Team Member Impersonation',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'admin.web.legacy.houston.company-team-member-impersonation.show',
            icon: RefreshIcon,
            routeNamesToCheckIfCurrent: [
              'admin.web.legacy.houston.company-team-member-impersonation.show',
            ],
          },
        ],
      },
      {
        show: true,
        name: 'Links',
        description: null,
        children: [
          {
            show: true,
            name: 'Stripe',
            description: 'Manage subscriptions and billing for customers',
            routeIsInertia: false,
            targetRouteName: 'admin.web.legacy.houston.stripe.show',
            icon: CreditCardIcon,
            routeNamesToCheckIfCurrent: null,
          },
          {
            show: true,
            name: 'Nova',
            description: 'Main admin panel',
            routeIsInertia: false,
            targetRouteName: 'admin.web.legacy.houston.nova.show',
            icon: SparklesIcon,
            routeNamesToCheckIfCurrent: null,
          },
          {
            show: true,
            name: 'Horizon',
            description: 'Monitor job processing',
            routeIsInertia: false,
            targetRouteName: 'admin.web.legacy.houston.horizon.show',
            icon: CollectionIcon,
            routeNamesToCheckIfCurrent: null,
          },
        ],
      },
      {
        show: true,
        name: 'Reporting',
        description: 'View our most important reports in Metabase.',
        children: [
          {
            show: true,
            name: 'Billing',
            description: null,
            routeIsInertia: false,
            targetRouteName: 'admin.web.legacy.houston.reports.billing.show',
            icon: CashIcon,
            routeNamesToCheckIfCurrent: null,
          },
          {
            show: true,
            name: 'Product Usage',
            description: null,
            routeIsInertia: false,
            targetRouteName: 'admin.web.legacy.houston.reports.product-usage.show',
            icon: ChartPieIcon,
            routeNamesToCheckIfCurrent: null,
          },
          {
            show: true,
            name: 'System Perfomance',
            description: null,
            routeIsInertia: false,
            targetRouteName: 'admin.web.legacy.houston.reports.system-performance.show',
            icon: ShieldCheckIcon,
            routeNamesToCheckIfCurrent: null,
          },
        ],
      },
      {
        show: true,
        name: 'Feature Management',
        description: null,
        children: [
          {
            show: true,
            name: 'Marketing Material Templates',
            description: null,
            routeIsInertia: true,
            targetRouteName: 'admin.web.legacy.houston.marketing-material-templates.index',
            icon: RefreshIcon,
            routeNamesToCheckIfCurrent: ['admin.web.legacy.houston.marketing-material-templates.*'],
          },
        ],
      },
      {
        show: true,
        name: 'Demos',
        description: null,
        children: [
          {
            show: true,
            name: 'Form Usage Demo',
            description: 'Demo usage of our form component structure',
            routeIsInertia: true,
            targetRouteName: 'admin.web.legacy.houston.demo-form.show',
            icon: TemplateIcon,
            routeNamesToCheckIfCurrent: ['admin.web.legacy.houston.demo-form.show'],
          },
        ],
      },
    ],
  }
}
