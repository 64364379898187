import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

export function useAuth() {
  const page = usePage()

  /*
   * Primary Auth Entities
   */
  const authCompanyUser = computed(() => page.props.auth?.company_user)
  const authCompanyTeamMember = computed(() => page.props.auth?.company_team_member)
  const authCompanyTeamMembers = computed(() => page.props.auth?.company_team_members)
  const authCompany = computed(() => page.props.auth?.company)
  const authOrganization = computed(() => page.props.auth?.organization)
  const isCompanyUser = computed(() => !!authCompanyUser.value)

  /*
   * Additional attributes required for display in layout sidebar or header
   */
  const integrationRequiringAuthorization = computed(
    () => page.props.auth?.integration_requiring_authorization ?? false
  )

  const integrationRequiringConfigurationUpdate = computed(
    () => page.props.auth?.integration_requiring_configuration_update ?? false
  )

  const integrationExpiringSoon = computed(
    () => page.props.auth?.integration_expiring_soon ?? false
  )

  const needsHomeBase = computed(() => page.props.auth?.needs_home_base)

  const portalAppConfigRequiringAction = computed(
    () => page.props.auth?.portal_app_config_requiring_action ?? false
  )

  const organizationNeedsBillingTaxDetails = computed(
    () => page.props.auth?.organization_needs_billing_tax_details
  )

  const otherTeamMemberNeedsHomeBase = computed(
    () => page.props.auth?.other_team_member_needs_home_base
  )

  return {
    authCompanyUser,
    authCompanyTeamMember,
    authCompanyTeamMembers,
    authCompany,
    authOrganization,
    isCompanyUser,
    integrationRequiringAuthorization,
    integrationRequiringConfigurationUpdate,
    integrationExpiringSoon,
    needsHomeBase,
    portalAppConfigRequiringAction,
    otherTeamMemberNeedsHomeBase,
    organizationNeedsBillingTaxDetails,
  }
}
