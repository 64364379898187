<template>
  <ComboboxOption :value="option" as="template" v-slot="{ active }">
    <div
      class="group flex select-none items-center rounded-md p-2 cursor-pointer"
      :class="[active && 'bg-gray-50 text-gray-900']"
    >
      <component :is="optionComponent" :option="option" />
    </div>
  </ComboboxOption>
</template>

<script setup>
import { computed } from 'vue'
import { ComboboxOption } from '@headlessui/vue'
import CommandPaletteNavOption from './CommandPaletteNavOption'
import CommandPaletteCompanyOption from './CommandPaletteCompanyOption'
import CommandPaletteCompanyTeamMemberOption from './CommandPaletteCompanyTeamMemberOption'
import CommandPaletteCustomerOption from './CommandPaletteCustomerOption'
import CommandPaletteCustomerTeamOption from './CommandPaletteCustomerTeamOption'
import CommandPaletteListingOption from './CommandPaletteListingOption'
import CommandPaletteOrderOptionVue from './CommandPaletteOrderOption'

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  option: {
    type: Object,
    required: true,
  },
})

const optionComponent = computed(() => {
  return {
    COMPANY: CommandPaletteCompanyOption,
    COMPANY_TEAM_MEMBER: CommandPaletteCompanyTeamMemberOption,
    CUSTOMER: CommandPaletteCustomerOption,
    CUSTOMER_TEAM: CommandPaletteCustomerOption,
    ROUTE: CommandPaletteNavOption,
    LISTING: CommandPaletteListingOption,
    ORDER: CommandPaletteOrderOptionVue,
  }[props.option.type]
})
</script>
