<template>
  <SharedSidebarItem
    v-if="!disabled"
    :name="name"
    :image="image"
    :iconComponent="iconComponent"
    :tag="tag"
    :targetRouteName="targetRouteName"
    :targetRouteAttributes="targetRouteAttributes"
    :isInertiaLink="isInertiaLink"
    :routeNamesToCheckIfCurrent="routeNamesToCheckIfCurrent"
    :itemOfPrimaryColumn="itemOfPrimaryColumn"
    :ghost="ghost"
    :badge="badge"
    :ordersRequiringActionCount="ordersRequiringActionCount"
    @itemClicked="handleItemClick"
  />
  <ItemDisabled v-else :name="name" :iconComponent="iconComponent" placement="auto" />
</template>

<script setup>
import SharedSidebarItem from '@/Components/Shared/Navigation/Sidebar/SidebarItem.vue'
import ItemDisabled from '@/Components/Shared/Navigation/ItemDisabled.vue'

import { inject } from 'vue'

import { useSecondaryNavigation } from '@/Hooks/Company/Navigation/useSecondaryNavigation'
import { useOrdersRequiringAction } from '@/Hooks/Company/useOrdersRequiringAction'

const props = defineProps({
  name: String,
  disabled: {
    type: Boolean,
    default: false,
  },
  image: String,
  iconComponent: Function,
  tag: String,
  targetRouteName: String,
  targetRouteAttributes: Object,
  isInertiaLink: Boolean,
  routeNamesToCheckIfCurrent: Array,
  itemOfPrimaryColumn: Boolean,
  ghost: {
    type: Boolean,
    default: false,
  },
  badge: String,
})

const { count: ordersRequiringActionCount } = useOrdersRequiringAction()
const { secondaryNavigationForCurrentUrl } = useSecondaryNavigation()
const resetSecondaryColumnOpen = inject('resetSecondaryColumnOpen')

const handleItemClick = (e) => {
  if (
    props.itemOfPrimaryColumn &&
    props.targetRouteName === secondaryNavigationForCurrentUrl()?.overviewRouteName
  ) {
    resetSecondaryColumnOpen()
    e.preventDefault()
  }
}
</script>
