import { useTenant } from '@/Hooks/Shared/useTenant'

export function useFeatureFlags() {
  const { tenant } = useTenant()

  // use the tenant to get the feature flags instead of useAuth
  // as some actions can take place with unauthroized users.
  const featureFlags = tenant.value.company.feature_flags

  function hasFeatureFlag(flag) {
    return featureFlags.includes(flag)
  }

  return {
    featureFlags,
    hasFeatureFlag,
  }
}
